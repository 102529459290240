@import './lightbox';

.site-button {
  &.active {
    font-weight: 600;
    text-decoration: none;
    background-image: linear-gradient(
      -90deg,
      rgba($feedback-color, 1) 0%,
      rgba($feedback-color, 0.8) 100%
    );
  }
}

.site-button:focus {
  background-image: linear-gradient(
    -90deg,
    rgba($feedback-color, 1) 0%,
    rgba($feedback-color, 0.8) 100%
  );
}

.logo {
  background-image: url("/help/en/assets/images/logo_cyberwatch.png");
  background-position: center top;
  background-size: 100% auto;
}
@media (prefers-color-scheme: dark) {
  .logo {
    background-image: url('/help/en/assets/images/logo_cyberwatch_white.png');
  }
}

.logo-footer {
  display: inline-block;
  width: 121px;
  height: 18px;
}

.logo-header {
    display: inline-block;
    width: 168px;
    height: 25px;
}
